import http from '@/utils/http';

export function GetAllActiveList(){
	return http.get('/v1/learn_activities')
}
export function GetActiveDetails(params){
	return http.get(`/v1/learn_activities/${params}`)
}
export function GetCurrentActivityUserList(params){
	return http.get(`/v1/learn_activities/${params}/user`)
}
export function AddActivity(params){
	return http.post(`/v1/learn_activities/${params}`)
}
export function GetCurrentActivityUserClockInList(params,pageNo,pageSize){
	return http.get(`/v1/learn_activities/${params}/record?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export function GetUserRewards(params){
	return http.get(`/v1/learn_activities/${params}/rewards`)
}